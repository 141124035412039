<template>
  <modal-wrapper @close="close" :cancellable="true">
    <div class="modal__teacher">
      <div class="modal__teacher__title">
        <div class="modal__teacher__title__img">
          <img v-if="isSchoolOrder" :src="avatar" alt="avatar" />
          <img v-if="isLogopedistOrder" :src="avatar" alt="avatar" />
          <img v-if="isPreSchoolOrder" :src="avatar" alt="avatar" />
        </div>
        <div class="modal__teacher__title__block">
          <div v-if="isSchoolOrder" class="modal__teacher__title__block__fio">
            {{
              teacherDetails?.attributes?.middleName ? teacherDetails?.attributes?.middleName : ''
            }}
            {{
              teacherDetails?.attributes?.firstName ? teacherDetails?.attributes?.firstName : ''
            }}
            {{ teacherDetails?.attributes?.lastName ? teacherDetails?.attributes?.lastName : '' }}
          </div>
          <div v-if="isLogopedistOrder" class="modal__teacher__title__block__fio">
            {{
              logopedistDetails?.attributes?.middleName
                ? logopedistDetails?.attributes?.middleName
                : ''
            }}
            {{
              logopedistDetails?.attributes?.firstName
                ? logopedistDetails?.attributes?.firstName
                : ''
            }}
            {{
              logopedistDetails?.attributes?.lastName
                ? logopedistDetails?.attributes?.lastName
                : ''
            }}
          </div>
          <div v-if="isPreSchoolOrder" class="modal__teacher__title__block__fio">
            {{
              preschoolTeacherDetails?.attributes?.middleName
                ? preschoolTeacherDetails?.attributes?.middleName
                : ''
            }}
            {{
              preschoolTeacherDetails?.attributes?.firstName
                ? preschoolTeacherDetails?.attributes?.firstName
                : ''
            }}
            {{
              preschoolTeacherDetails?.attributes?.lastName
                ? preschoolTeacherDetails?.attributes?.lastName
                : ''
            }}
          </div>
          <div v-if="isSchoolOrder" class="modal__teacher__title__block__id">
            ID {{ teacherDetails.id }}
          </div>
          <div v-if="isLogopedistOrder" class="modal__teacher__title__block__id">
            ID {{ logopedistDetails.id }}
          </div>
          <div v-if="isPreSchoolOrder" class="modal__teacher__title__block__id">
            ID {{ preschoolTeacherDetails.id }}
          </div>
          <div
            v-if="isSchoolOrder"
            class="modal__teacher__title__block__button"
            @click="goTo(teacherDetails.id)"
          >
            Карточка педагога
            <img src="@/assets/icons/arrow-simple-forward.svg" alt="arrow" />
          </div>
          <div
            v-if="isLogopedistOrder"
            class="modal__teacher__title__block__button"
            @click="goTo(logopedistDetails.id)"
          >
            Карточка педагога
            <img src="@/assets/icons/arrow-simple-forward.svg" alt="arrow" />
          </div>
          <div
            v-if="isPreSchoolOrder"
            class="modal__teacher__title__block__button"
            @click="goTo(preschoolTeacherDetails.id)"
          >
            Карточка педагога
            <img src="@/assets/icons/arrow-simple-forward.svg" alt="arrow" />
          </div>
        </div>
      </div>
      <div class="modal__teacher__info">
        <div class="modal__teacher__info__block">
          <p class="modal__teacher__info__block__text">Местное время</p>
          <div class="modal__teacher__info__block__input">
            {{
              new Date(
                new Date().getTime() +
                  new Date().getTimezoneOffset() * 60 * 1000 +
                  teacherDetails?.attributes?.timezone * 60 * 60 * 1000
              ).toLocaleTimeString()
            }}
            <span style="margin-left: 0.35rem" v-if="teacherDetails?.attributes?.timezone !== 3"
              >({{ teacherDetails?.attributes?.timezone - 3 }} МСК)</span
            >
          </div>
        </div>
        <div class="modal__teacher__info__block">
          <p class="modal__teacher__info__block__text">Телефон педагога</p>
          <div class="modal__teacher__info__block__input">
            <span
              v-if="isSchoolOrder"
              style="display: flex; align-items: flex-end; height: 20px"
              >{{ teacherDetails.phone }}</span
            >
            <span
              v-if="isLogopedistOrder"
              style="display: flex; align-items: flex-end; height: 20px"
              >{{ logopedistDetails.phone }}</span
            >
            <span
              v-if="isPreSchoolOrder"
              style="display: flex; align-items: flex-end; height: 20px"
              >{{ preschoolTeacherDetails.phone }}</span
            >
            <a
              v-if="isSchoolOrder"
              style="
                display: flex;
                align-items: center;
                height: 20px;
                width: 20px;
                margin-left: 8px;
              "
              :href="'tel:' + `${teacherDetails.phone ? teacherDetails.phone : ''} `"
            >
              <img src="@/assets/icons/phone-call.svg" alt="phone call" />
            </a>
            <a
              v-if="isLogopedistOrder"
              style="
                display: flex;
                align-items: center;
                height: 20px;
                width: 20px;
                margin-left: 8px;
              "
              :href="'tel:' + `${logopedistDetails.phone ? logopedistDetails.phone : ''} `"
            >
              <img src="@/assets/icons/phone-call.svg" alt="phone call" />
            </a>
            <a
              v-if="isPreSchoolOrder"
              style="
                display: flex;
                align-items: center;
                height: 20px;
                width: 20px;
                margin-left: 8px;
              "
              :href="
                'tel:' +
                `${preschoolTeacherDetails.phone ? preschoolTeacherDetails.phone : ''} `
              "
            >
              <img src="@/assets/icons/phone-call.svg" alt="phone call" />
            </a>
          </div>
        </div>
      </div>

      <div
        v-if="tutorActualComment"
        class="modal__teacher__note"
      >
        <p class="modal__teacher__types__block__text">Примечание</p>

        <div class="modal__teacher__university__input">
          {{ tutorActualComment }}
        </div>
      </div>

      <div class="modal__teacher__types">
        <div v-if="isSchoolOrder" class="modal__teacher__types__block">
          <p class="modal__teacher__types__block__text">Типы занятий</p>
          <div class="modal__teacher__types__block__info">
            <div
              v-for="lessonsType in teacherDetails?.teacher?.lessonsTypes"
              :key="lessonsType.id"
              class="modal__teacher__types__block__info__block"
            >
              {{ lessonsType.lessonType.name }}
            </div>
          </div>
        </div>
        <div v-if="isLogopedistOrder" class="modal__teacher__types__block">
          <p class="modal__teacher__types__block__text">Перечень предпочтительных нарушений</p>
          <div class="modal__teacher__types__block__info">
            <div
              v-for="speechDisordersPreference in logopedistDetails?.logopedist
                .speechDisordersPreferences"
              :key="speechDisordersPreference.id"
              class="modal__teacher__types__block__info__block"
            >
              {{ speechDisordersPreference.speechDisorder.name }}
            </div>
          </div>
        </div>
        <div v-if="isSchoolOrder" class="modal__teacher__types__block">
          <p class="modal__teacher__types__block__text">Предметы</p>
          <div class="modal__teacher__types__block__info">
            <div
              v-for="subject in teacherDetails?.teacher?.subjects"
              :key="subject.id"
              class="modal__teacher__types__block__info__block"
            >
              {{ subject.subject.name }}
            </div>
          </div>
        </div>
        <div v-if="isLogopedistOrder" class="modal__teacher__types__block">
          <p class="modal__teacher__types__block__text">Диагностические технологии</p>
          <div class="modal__teacher__types__block__info">
            <div
              v-for="diagnosticTechnologiesPreference in logopedistDetails?.logopedist
                .diagnosticTechnologiesPreferences"
              :key="diagnosticTechnologiesPreference.id"
              class="modal__teacher__types__block__info__block"
            >
              {{ diagnosticTechnologiesPreference.diagnosticTechnology.name }}
            </div>
          </div>
        </div>
      </div>
      <div class="modal__teacher__university">
        <p class="modal__teacher__university__text">Вуз</p>
        <div v-if="isSchoolOrder" class="modal__teacher__university__input">
          {{ teacherDetails?.teacher?.educations[0]?.university }}
        </div>
        <div v-if="isLogopedistOrder" class="modal__teacher__university__input">
          {{ logopedistDetails?.logopedist?.educations[0]?.university }}
        </div>
        <div v-if="isPreSchoolOrder" class="modal__teacher__university__input">
          {{ preschoolTeacherDetails?.preschoolTeacher?.educations[0]?.university }}
        </div>
      </div>
      <div class="modal__teacher__study">
        <div class="modal__teacher__study__block">
          <p class="modal__teacher__study__block__text">Год окончания</p>
          <div v-if="isSchoolOrder" class="modal__teacher__study__block__input">
            {{ teacherDetails?.teacher?.educations[0]?.year }}
          </div>
          <div v-if="isLogopedistOrder" class="modal__teacher__study__block__input">
            {{ logopedistDetails?.logopedist?.educations[0]?.year }}
          </div>
          <div v-if="isPreSchoolOrder" class="modal__teacher__study__block__input">
            {{ preschoolTeacherDetails?.preschoolTeacher?.educations[0]?.year }}
          </div>
        </div>
        <div class="modal__teacher__study__block">
          <p class="modal__teacher__study__block__text">Виды высшего образования</p>
          <div v-if="isSchoolOrder" class="modal__teacher__study__block__input">
            {{ teacherDetails?.teacher?.educations[0]?.type?.name }}
          </div>
          <div v-if="isLogopedistOrder" class="modal__teacher__study__block__input">
            {{
              logopedistDetails?.logopedist?.educations[0]?.type?.name
                ? logopedistDetails?.logopedist?.educations[0]?.type?.name
                : 'Не заполнено'
            }}
          </div>
          <div v-if="isPreSchoolOrder" class="modal__teacher__study__block__input">
            {{
              preschoolTeacherDetails.preschoolTeacher.educations[0]?.type?.name
                ? preschoolTeacherDetails.preschoolTeacher.educations[0]?.type?.name
                : 'Не заполнено'
            }}
          </div>
        </div>
      </div>
      <div class="modal__teacher__experience">
        <p class="modal__teacher__experience__text">Преподавательский стаж</p>
        <div v-if="isSchoolOrder" class="modal__teacher__experience__input">
          {{ teacherDetails?.teacher?.teachingServiceYears }} лет
        </div>
        <div v-if="isLogopedistOrder" class="modal__teacher__experience__input">
          {{ logopedistDetails?.logopedist?.teachingServiceYears }} лет
        </div>
        <div v-if="isPreSchoolOrder" class="modal__teacher__experience__input">
          {{ preschoolTeacherDetails?.preschoolTeacher?.teachingServiceYears }} лет
        </div>
      </div>
      <div class="modal__teacher__about">
        <p class="modal__teacher__about__text">О себе</p>
        <div v-if="isSchoolOrder" class="modal__teacher__about__input">
          {{ teacherDetails?.teacher?.personalInfo }}
        </div>
        <div v-if="isLogopedistOrder" class="modal__teacher__about__input">
          {{ logopedistDetails?.logopedist?.personalInfo }}
        </div>
        <div v-if="isPreSchoolOrder" class="modal__teacher__about__input">
          {{ preschoolTeacherDetails?.preschoolTeacher?.personalInfo }}
        </div>
      </div>
    </div>
  </modal-wrapper>
</template>
<script>
import ModalWrapper from '@/basic/ModalWrapper.vue'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import userApi from '@/api/users'

export default {
  name: 'TeacherModal',
  components: { ModalWrapper },
  data() {
    return {
      avatar: require(`@/assets/icons/user.svg`)
    }
  },
  async created() {
    if (this.orderType === 1) {
      await Promise.all([this.getTeacher(this.params.pedagog.id)])
    }
    if (this.orderType === 2) {
      await Promise.all([this.getLogopedist(this.params.pedagog.id)])
    }
    if (this.orderType === 3) {
      await Promise.all([this.getPreschoolTeacher(this.params.pedagog.id)])
    }
  },
  async mounted() {
    try {
      const fileData = await userApi.getAvatar(this.params.pedagog.id)
      if (fileData?.status === 200) {
        const buffer = Buffer.from(fileData.data)
        const base64String = buffer.toString('base64')
        this.avatar = `data:image/jpg;base64,${base64String}`
      }
    } catch (e) {
      console.warn(e)
      this.avatar = require(`@/assets/icons/user.svg`)
    }
  },
  beforeDestroy() {
    this.setTeacherDetails({})
  },
  computed: {
    ...mapGetters({
      logopedistDetails: 'logopedistDetails',
      teacherDetails: 'teacherDetails',
      preschoolTeacherDetails: 'preschoolTeacherDetails'
    }),
    isSchoolOrder() {
      return this.orderType === 1
    },
    isLogopedistOrder() {
      return this.orderType === 2
    },
    tutorActualComment() {
      if (this.isSchoolOrder) {
        return this.teacherDetails.teacher.comments[0]?.text || ''
      } else if (this.isLogopedistOrder) {
        return this.logopedistDetails.logopedist.comments[0]?.text || ''
      }

      return ''
    },
    isPreSchoolOrder() {
      return this.orderType === 3
    },
    params() {
      return this.$store.getters['modalService']
    },
    orderType() {
      return this.params.orderType ?? 1
    }
  },
  methods: {
    ...mapActions(['getTeacher', 'getLogopedist', 'getPreschoolTeacher']),
    ...mapMutations(['setTeacherDetails']),
    close(config = { success: true, result: false }) {
      this.$store.dispatch('closeModal', config)
    },
    goTo(id) {
      this.$router.push(`/teachers/${id}`)
      this.close()
    }
  }
}
</script>

<style scoped lang="scss">
.modal__teacher {
  width: 36.375rem;
  padding: 1.5rem;
  &__note {
    margin-bottom: 2.25rem;
  }
  &__title {
    display: flex;
    margin-bottom: 2.25rem;
    &__img {
      width: 7.5rem;
      height: 7.5rem;
      display: flex;
      border-radius: 50%;
      margin-right: 2.25rem;
      img {
        width: 7.5rem;
        height: 7.5rem;
        object-fit: cover;
        border-radius: 50%;
      }
    }
    &__block {
      margin: 1.406rem 0;
      &__fio {
        display: flex;
        font-weight: 700;
        font-size: 1rem;
        line-height: 1.172rem;
        color: rgba(42, 43, 42, 1);
        margin-bottom: 0.5rem;
      }
      &__id {
        display: flex;
        font-weight: 400;
        font-size: 0.875rem;
        line-height: 1.026rem;
        color: rgba(0, 0, 0, 1);
        margin-bottom: 0.5rem;
      }
      &__button {
        justify-content: space-between;
        align-items: center;
        width: 9.25rem;
        font-weight: 400;
        font-size: 0.75rem;
        line-height: 0.879rem;
        color: rgba(255, 255, 255, 1);
        background: rgba(217, 217, 217, 1);
        display: flex;
        border: 0.063rem solid rgba(209, 209, 209, 1);
        border-radius: 0.188rem;
        padding: 0.25rem 0.5rem;
        cursor: pointer;
      }
    }
  }
  &__info {
    display: flex;
    flex-direction: row;
    margin-bottom: 2.281rem;
    justify-content: space-between;
    &__block {
      display: flex;
      width: 15.938rem;
      flex-direction: column;
      &__text {
        display: flex;
        font-weight: 700;
        font-size: 0.813rem;
        line-height: 0.952rem;
        color: rgba(151, 150, 150, 1);
        margin-bottom: 1rem;
      }
      &__input {
        display: flex;
        font-weight: 400;
        font-size: 1rem;
        line-height: 1.172rem;
        color: rgba(0, 0, 0, 1);
      }
    }
  }
  &__types {
    display: flex;
    flex-direction: row;
    margin-bottom: 2.25rem;
    justify-content: space-between;
    &__block {
      display: flex;
      width: 15.938rem;
      flex-direction: column;
      &__text {
        display: flex;
        font-weight: 700;
        font-size: 0.813rem;
        line-height: 0.952rem;
        color: rgba(151, 150, 150, 1);
        margin-bottom: 1rem;
      }
      &__info {
        width: 15.938rem;
        border: 0.063rem solid rgba(188, 188, 188, 1);
        padding: 0.625rem;
        &__block {
          &:first-child {
            margin-top: 0 !important;
          }
          &:nth-child(1n + 1) {
            margin-top: 0.625rem;
          }
          border-bottom: 0.063rem solid rgba(188, 188, 188, 1);
          padding-bottom: 0.625rem;
          font-weight: 400;
          font-size: 1rem;
          line-height: 1.172rem;
          color: rgba(0, 0, 0, 1);
        }
      }
    }
  }
  &__university {
    display: flex;
    flex-direction: column;
    margin-bottom: 2.25rem;
    &__text {
      display: flex;
      font-weight: 700;
      font-size: 0.813rem;
      line-height: 0.952rem;
      color: rgba(151, 150, 150, 1);
      margin-bottom: 1rem;
    }
    &__input {
      height: 8.375rem;
      display: flex;
      border: 0.063rem solid rgba(188, 188, 188, 1);
      padding: 0.625rem;
      color: rgba(0, 0, 0, 1);
      font-weight: 400;
      font-size: 1rem;
      line-height: 1.172rem;
    }
  }
  &__study {
    display: flex;
    flex-direction: row;
    margin-bottom: 2.25rem;
    justify-content: space-between;
    &__block {
      display: flex;
      width: 15.938rem;
      flex-direction: column;
      &__text {
        display: flex;
        font-weight: 700;
        font-size: 0.813rem;
        line-height: 0.952rem;
        color: rgba(151, 150, 150, 1);
        margin-bottom: 1rem;
      }
      &__input {
        height: 2.438rem;
        display: flex;
        border: 0.063rem solid rgba(188, 188, 188, 1);
        padding: 0.625rem;
        color: rgba(0, 0, 0, 1);
        font-weight: 400;
        font-size: 1rem;
        line-height: 1.172rem;
      }
    }
  }
  &__experience {
    display: flex;
    flex-direction: column;
    margin-bottom: 2.25rem;
    width: 15.938rem;
    &__text {
      display: flex;
      font-weight: 700;
      font-size: 0.813rem;
      line-height: 0.952rem;
      color: rgba(151, 150, 150, 1);
      margin-bottom: 1rem;
    }
    &__input {
      height: 2.438rem;
      display: flex;
      border: 0.063rem solid rgba(188, 188, 188, 1);
      padding: 0.625rem;
      color: rgba(0, 0, 0, 1);
      font-weight: 400;
      font-size: 1rem;
      line-height: 1.172rem;
    }
  }
  &__about {
    display: flex;
    flex-direction: column;
    &__text {
      display: flex;
      font-weight: 700;
      font-size: 0.813rem;
      line-height: 0.952rem;
      color: rgba(151, 150, 150, 1);
      margin-bottom: 1rem;
    }
    &__input {
      min-height: 7.813rem;
      display: flex;
      border: 0.063rem solid rgba(188, 188, 188, 1);
      padding: 0.625rem;
      color: rgba(0, 0, 0, 1);
      font-weight: 400;
      font-size: 1rem;
      overflow-wrap: anywhere;
      line-height: 1.172rem;
    }
  }
}
</style>
